const lgaData = [
  {
    field1: "1",
    state: "ABIA(17)",
    data: [
      {
        lga: "ABA NORTH",
        chairmen: "CHIEF VICTOR UBANI",
      },
      {
        lga: "ABA SOUTH",
        chairmen: "CHERECHI WOGU",
      },
      {
        lga: "AROCHUKWU",
        chairmen: "MAURICE ONYEKACHI OKORO",
      },
      {
        lga: "BENDE",
        chairmen: "DR.HAGLER OKORIE",
      },
      {
        lga: "IKWUANO",
        chairmen: "BARR. STANLEY OJIGBO",
      },
      {
        lga: "ISIALANGWA NORTH",
        chairmen: "UDO NWOGWUGWU",
      },
      {
        lga: "ISIALANGWA SOUTH",
        chairmen: "CHIEF NKEM ONWUBIKO",
      },
      {
        lga: "ISUIKWUATO",
        chairmen: "DR. OSITA IGBE",
      },
      {
        lga: "OBINGWA",
        chairmen: "KINGSLEY NNAJI",
      },
      {
        lga: "OSISIOMA",
        chairmen: "DR. IKECHUKWU NWABEKE",
      },
      {
        lga: "OHAFIA",
        chairmen: "FRED IDIKA",
      },
      {
        lga: "UKWA EAST",
        chairmen: "MRS STELLA IGBOKWE",
      },
      {
        lga: "UKWA WEST",
        chairmen: "CHIEF SYLVANUS NWAJI",
      },
      {
        lga: "UMUAHIA NORTH",
        chairmen: "CHIEF LUCKY AKABUIKE",
      },
      {
        lga: "UMUAHIA SOUTH",
        chairmen: "DR. NDUKWE ADINDU",
      },
      {
        lga: "UGWUNNAGBO",
        chairmen: "CHIEF PETER AGUOMBA",
      },
      {
        lga: "UMUNNEOCHI",
        chairmen: "DR. EZE CHIKAMNAYO",
      },
    ],
  },
  {
    field1: "2",
    state: "ADAMAWA(21)",
    data: [
      {
        lga: "DEMSA",
        chairmen: "ELKANA KADOS FWA",
      },
      {
        lga: "FUFORE",
        chairmen: "MUSA JAURO UMARU",
      },
      {
        lga: "GANYE",
        chairmen: "HABILA M.ISTIFANUS",
      },
      {
        lga: "GIREI",
        chairmen: "JUDAH AMISA",
      },
      {
        lga: "GOMBI",
        chairmen: "DIMAS A.SHEKEL",
      },
      {
        lga: "GUYUK",
        chairmen: "KASSAH DAMPI SHALLOM",
      },
      {
        lga: "HONG",
        chairmen: "JAMES PUKUMA",
      },
      {
        lga: "JADA",
        chairmen: "ABDULRAHMAN A. BAKALLA",
      },
      {
        lga: "LAMURDE",
        chairmen: "WILLIAM P. BURTO",
      },
      {
        lga: "MADAGALI",
        chairmen: "HON AIDEN TSUKOM PALLAM",
      },
      {
        lga: "MAIHA",
        chairmen: "IDI ALI  AMINU",
      },
      {
        lga: "MAYO-BELWA",
        chairmen: "GERSHON KASUWA",
      },
      {
        lga: "MICHIKA",
        chairmen: "MICHAEL SHEHU",
      },
      {
        lga: "MUBI NORTH",
        chairmen: "SUKEMAN YAHAYA SUDI",
      },
      {
        lga: "MUBI SOUTH",
        chairmen: "JINGI RUFAI KWAJA",
      },
      {
        lga: "NUMAN",
        chairmen: "INNOCENT KOTO",
      },
      {
        lga: "SHELLENG",
        chairmen: "DANJUMA KEDI ABDULLAHI",
      },
      {
        lga: "SONG",
        chairmen: "ABDU SALAM GIDADO",
      },
      {
        lga: "TOUNGO",
        chairmen: "KEFAS CALVIN",
      },
      {
        lga: "YOLA NORTH",
        chairmen: "IBRAHIM BAPPA",
      },
      {
        lga: "YOLA SOUTH",
        chairmen: "SALIHU USMAN MALKOHI",
      },
    ],
  },
  {
    field1: "3",
    state: "AKWA-IBOM(31)",
    data: [
      { lga: "ABAK", chairmen: "IMOH WILLIAMS" },
      {
        lga: "EASTERN OBOLO",
        chairmen: "ABRAHAM MATTHIAS ODION",
      },
      {
        lga: "EKET",
        chairmen: "MR FRANK ARCHIBONG",
      },
      {
        lga: "ESIT-EKET",
        chairmen: "INIOBONG R. NNAMSO",
      },
      {
        lga: "ESSIEN UDIM",
        chairmen: "BARR. PAULINUS J. IDIO",
      },
      {
        lga: "ETIM-EKPO",
        chairmen: "IMEH PAUL AFIA",
      },
      {
        lga: "EITNAN",
        chairmen: "HON. CLETUS EKPO",
      },
      {
        lga: "IBENO",
        chairmen: "HIGH CHIEF WILLIAMS MKPAH",
      },
      {
        lga: "IBESIKPO-ASUTAN",
        chairmen: "RT. HON SYLVESTER GEORGE",
      },
      {
        lga: "IBIONO-IBOM",
        chairmen: "RT. HON EKOMABASI AKPAN",
      },
      {
        lga: "IKA",
        chairmen: "HON. ANOTHONY UDOUSUNG",
      },
      {
        lga: "IKONO",
        chairmen: "HON. NYAKNO UWEMDIMO OKOIBU",
      },
      {
        lga: "IKOT ABASI",
        chairmen: "HON. DAVID DAVID ESHIET",
      },
      {
        lga: "IKOT EKPENE",
        chairmen: "HON. UNYIME OKON ETIM",
      },
      {
        lga: "INI",
        chairmen: "MR . IDAISIN ISRAEL",
      },
      {
        lga: "ITU",
        chairmen: "HON OKON ETIM OKON",
      },
      {
        lga: "MBO",
        chairmen: "ASUKWO  EFFIONG EYO",
      },
      {
        lga: "MKPAT-ENIN",
        chairmen: "HON. EKANEM BROWN",
      },
      {
        lga: "NSIT-ATAI",
        chairmen: "EMEM IBANGA",
      },
      {
        lga: "NSIT-IBOM",
        chairmen: "HON. ERIC EFFIONG AKPAN",
      },
      {
        lga: "NSIT-UBIUM",
        chairmen: "OTOBONG EDEMIDONG",
      },
      {
        lga: "OBOT-AKARA",
        chairmen: "ETUBOM ANIETIE AKPAETTOR",
      },
      {
        lga: "OKOBO",
        chairmen: "UBUO E. UDUO",
      },
      {
        lga: "ONNA",
        chairmen: "JOSHUA T. NDOHO",
      },
      {
        lga: "ORON",
        chairmen: "ANTHONY B.ETIM",
      },
      {
        lga: "ORUK ANAM",
        chairmen: "PRINCE UBONG B. IDIONG",
      },
      {
        lga: "UKANAFUN",
        chairmen: "ISRAEL DEMAS IDEH",
      },
      {
        lga: "UDUNG UKO",
        chairmen: "GODWIN E. OKPONUNG",
      },
      {
        lga: "URUAN",
        chairmen: "HON, SURV. INIOBONG EKPENYONG",
      },
      {
        lga: "URUE-OFFONG/ORUKO",
        chairmen: "HON.(PRINCE) UMANAH EFOMBRUH",
      },
      {
        lga: "UYO",
        chairmen: "DR. UWEMEDIMO UDO",
      },
    ],
  },
  {
    field1: "4",
    state: "ANAMBRA(21)",
    data: [
      { lga: "AGUATA", chairmen: "HON ECHE EZEIBE" },
      {
        lga: "ANAMBRA EAST",
        chairmen: "HON BARR NWEKE OBINWEKE",
      },
      {
        lga: "ANAMBRA WEST",
        chairmen: "HON. SYLVESTER ORJI",
      },
      {
        lga: "ANAOCHA",
        chairmen: "HON PATRICK ONYEDUM",
      },
      {
        lga: "AWKA-NORTH",
        chairmen: "HON FERDINARD ONWUJE",
      },
      {
        lga: "AWKA-SOUTH",
        chairmen: "MR LEO NWUBA",
      },
      {
        lga: "AYAMELUM",
        chairmen: "DR UCHENNA NWUNELI",
      },
      {
        lga: "DANUKOFIA",
        chairmen: "HON CHUKWUEMEKA PATRICK CHINWEZE",
      },
      {
        lga: "EKWUS IGO",
        chairmen: "MR AUSTIN ENENDU",
      },
      {
        lga: "IDEMILI NORTH",
        chairmen: "MR RAPHEAL NNABUIFE",
      },
      {
        lga: "IDEMILI SOUTH",
        chairmen: "HON DON UNACHUKWU",
      },
      {
        lga: "IHIALA",
        chairmen: "HON SIR JUDE J.OBI",
      },
      {
        lga: "NJIKOKA",
        chairmen: "CHIEF GEORGE OZO",
      },
      {
        lga: "NNEWI NORTH",
        chairmen: "HON PRINCE CHUKWUDI",
      },
      {
        lga: "NNEWI SOUTH",
        chairmen: "HON CHIELOKA OKOYE",
      },
      {
        lga: "OGBARU",
        chairmen: "ARINZECHUKWU AWOGU",
      },
      {
        lga: "ONITSHA NORTH",
        chairmen: "MR PATRICK AGHA MBA",
      },
      {
        lga: "ONITSHA SOUTH",
        chairmen: "CHIEF EMEKA ASONYA",
      },
      {
        lga: "ORUMBA NORTH",
        chairmen: "HON .BARR OBINNA IKWUETO",
      },
      {
        lga: "ORUMBA SOUTH",
        chairmen: "HON BARR CHUKWUKA RAYMOND ONYEGU",
      },
      {
        lga: "OYI",
        chairmen: "ENGR. NNAMDI NWADIOGBU",
      },
    ],
  },
  {
    field1: "5",
    state: "BAUCHI(20)",
    data: [
      { lga: "ALKALERI", chairmen: "YUSUF GARBA" },
      {
        lga: "BAUCHI",
        chairmen: "MOHAMMED BABA- MA`AJI",
      },
      {
        lga: "BOGORO",
        chairmen: "ILLIYA HABILA",
      },
      {
        lga: "DAMBAM",
        chairmen: "WIZIRI AYUBA",
      },
      {
        lga: "DARAZO",
        chairmen: "MAHOOM BELLO",
      },
      {
        lga: "DASS",
        chairmen: "MUHAMMED SULEIMAN",
      },
      {
        lga: "GAMAWA",
        chairmen: "BABAYO KASUWA",
      },
      {
        lga: "GANJUWA",
        chairmen: "DAYIABU KARIYA",
      },
      {
        lga: "GIADE",
        chairmen: "MOHAMMED SALEH",
      },
      {
        lga: "ITAS/GADAU",
        chairmen: "ABDULLAHI MAIGARI",
      },
      {
        lga: "JAMA`RE",
        chairmen: "SAMAILA JARMA",
      },
      {
        lga: "KATAGUM",
        chairmen: "ABDULLAHI KUDA",
      },
      {
        lga: "KIRFI",
        chairmen: "GARBA MUSA",
      },
      {
        lga: "MISAU",
        chairmen: "ABUBAKAR MISAU",
      },
      {
        lga: "NINGI",
        chairmen: "MAMUDA HASSAN",
      },
      {
        lga: "SHIRA",
        chairmen: "MAHMUD ABBA",
      },
      {
        lga: "TAFAWA BALEWA",
        chairmen: "DANIEL DANJUMA",
      },
      {
        lga: "TORO",
        chairmen: "ADAMU DANYARO",
      },
      {
        lga: "WARJI",
        chairmen: "ADAMU MOHAMMED",
      },
      {
        lga: "ZAKI",
        chairmen: "YAHUZA ABDULKADIR",
      },
    ],
  },
  {
    field1: "6",
    state: "BAYELSA(8)",
    data: [
      { lga: "BRASS", chairmen: "MR VICTOR ISAIAH" },
      {
        lga: "EKEREMOR",
        chairmen: "CHIEF PEREKEME PETULA",
      },
      {
        lga: "KOLOKUMA/OPOKUMA",
        chairmen: "MR DENGIYE UBARUGU",
      },
      {
        lga: "NEMBE",
        chairmen: "HON. ALALIBO WEST",
      },
      {
        lga: "OGBIA",
        chairmen: "MR TURNER EBINYO",
      },
      {
        lga: "SAGBAMA",
        chairmen: "MR ALAH EMBELEAKPO",
      },
      {
        lga: "SOUTHERN IJAW",
        chairmen: "MR NIGERIA KIA",
      },
      {
        lga: "YENEGOA",
        chairmen: "MR LAWRENCE NIMIZUOA",
      },
    ],
  },
  {
    field1: "7",
    state: "BENUE(23)",
    data: [
      { lga: "ADO", chairmen: "OCHE OGABA JAMES" },
      {
        lga: "AGATU",
        chairmen: "ADOYI SULE",
      },
      {
        lga: "APA",
        chairmen: "OKOCHE AMEH",
      },
      {
        lga: "BURUKU",
        chairmen: "DANIEL UKEHEMBA",
      },
      {
        lga: "GBOKO",
        chairmen: "JAMES  KACHINA",
      },
      {
        lga: "GUMA",
        chairmen: "ABA CALEB M",
      },
      {
        lga: "GWER EAST",
        chairmen: "ORTSERGA TERWASE",
      },
      {
        lga: "GWER WEST",
        chairmen: "MRS GRACE IGBABON",
      },
      {
        lga: "KATSINA ALA",
        chairmen: "HON. AVALUMUN ORYA ATERA",
      },
      {
        lga: "KONSHISHA",
        chairmen: "JIRGBA TERRUMUN",
      },
      {
        lga: "KWANDE",
        chairmen: "HON. MRS CHIANSON JOYCE",
      },
      {
        lga: "LOGO",
        chairmen: "HON. TERSEER AGBER ISAAC",
      },
      {
        lga: "MAKURDI",
        chairmen: "DYEGEH ANTHONY",
      },
      {
        lga: "OBI",
        chairmen: "EREBE AGNES",
      },
      {
        lga: "OGBADIBO",
        chairmen: "ONUH SAMUEL O.",
      },
      {
        lga: "OJU",
        chairmen: "MR CLEMENT ONAH",
      },
      {
        lga: "OKPOKWU",
        chairmen: "AUDU AMINA",
      },
      {
        lga: "OHIMINI",
        chairmen: "AGBO FAITH",
      },
      {
        lga: "OTURKPO",
        chairmen: "GEORGE ALI",
      },
      {
        lga: "TARKA",
        chairmen: "SUSWAM JULIANA",
      },
      {
        lga: "UKUM",
        chairmen: "AYUA ATEVE",
      },
      {
        lga: "USHONGO",
        chairmen: "ASAWA JOSEPH",
      },
      {
        lga: "VANDEIKYA",
        chairmen: "TYOER GARBA KUKASE",
      },
    ],
  },
  {
    field1: "8",
    state: "BORNO(27)",
    data: [
      { lga: "ABADAM", chairmen: "MODU AISAMI" },
      {
        lga: "ASKIRA/UBA",
        chairmen: "AHIJO ABUDULLAHI",
      },
      {
        lga: "BAMA",
        chairmen: "ABBA KACHALA",
      },
      {
        lga: "BAYO",
        chairmen: "IINUWA MOHAMMED",
      },
      {
        lga: "BIU",
        chairmen: "HARUNA IBRAHIM",
      },
      {
        lga: "CHIBOK",
        chairmen: "UMAR IBRAHIM",
      },
      {
        lga: "DAMBOA",
        chairmen: "PROFESSOR ADAMU ALOOMA",
      },
      {
        lga: "DIKWA",
        chairmen: "MUHAMMED GUMSAMA",
      },
      {
        lga: "GUBIO",
        chairmen: "SULUM BUKAR",
      },
      {
        lga: "GUZAMALA",
        chairmen: "KYARI UMAR",
      },
      {
        lga: "GWOZA",
        chairmen: "PROFESSOR IBRAHIM BUKAR",
      },
      {
        lga: "HAWUL",
        chairmen: "IBRAHIM HASSAN",
      },
      {
        lga: "JERE",
        chairmen: "UMAR TOM",
      },
      {
        lga: "KAGA",
        chairmen: "BUNU GONERI",
      },
      {
        lga: "KALA/BALGE",
        chairmen: "ABDULLAHI MUHAMMED",
      },
      {
        lga: "KONDUGA",
        chairmen: "MUHAMMED NUR",
      },
      {
        lga: "KUKAWA",
        chairmen: "AJI BUKAR",
      },
      {
        lga: "KWAYA KUSAR",
        chairmen: "SAMSON DIBAL",
      },
      {
        lga: "MAFA",
        chairmen: "ABACHA KAWU",
      },
      {
        lga: "MAGUMERI",
        chairmen: "LAWAN YAWUMI",
      },
      {
        lga: "MAIDUGURI(MMC)",
        chairmen: "ALI BOLORI",
      },
      {
        lga: "MARTE",
        chairmen: "ABUBAKAR JUBRIL",
      },
      {
        lga: "MOBBAR",
        chairmen: "BUNU KOLO",
      },
      {
        lga: "MONGUNO",
        chairmen: "IBRAHIM KYARI",
      },
      {
        lga: "NGALA",
        chairmen: "TIJANI MALA",
      },
      {
        lga: "NGANZAI",
        chairmen: "BUKAR ASHEIK",
      },
      {
        lga: "SHANI",
        chairmen: "YUSUFU ADAMU",
      },
    ],
  },
  {
    field1: "9",
    state: "CROSS RIVER(18)",
    data: [
      { lga: "ABI", chairmen: "FARATOR ROBINSON FREEMAN" },
      {
        lga: "AKAMKPA",
        chairmen: "HON .LINUS BASSEY ETIM",
      },
      {
        lga: "AKPABUYO",
        chairmen: "EMMANUEL OFFIONG BASSEY",
      },
      {
        lga: "BAKASSI",
        chairmen: "IYADIM AMBONI IYADIM",
      },
      {
        lga: "BEKWARA",
        chairmen: "DR. JOSEPHAT EMEKA",
      },
      {
        lga: "BIASE",
        chairmen: "ADA CHARLES EGWU",
      },
      {
        lga: "BOKI",
        chairmen: "PASTOR JOHN EWA",
      },
      {
        lga: "CALABAR-MUNICIPAL",
        chairmen: "EFFIONG NTA EKE",
      },
      {
        lga: "CALABAR SOUTH",
        chairmen: "MRS ESTHER BASSEY",
      },
      {
        lga: "ETUNG",
        chairmen: "COMRADE JOHN NKOM EKUK",
      },
      {
        lga: "IKOM",
        chairmen: "KINGSLEY EGUMI",
      },
      {
        lga: "OBANLIKU",
        chairmen: "MARGARET INDE UYILEWHOME",
      },
      {
        lga: "OBUBRA",
        chairmen: "BASSEY AGARA TOM",
      },
      {
        lga: "OBUDU",
        chairmen: "BONNY EWEH ERAYE",
      },
      {
        lga: "ODUKPANI",
        chairmen: "MRS. JUSTINA ETIM",
      },
      {
        lga: "OGOJA",
        chairmen: "HON. EMMANUEL ISHABOR",
      },
      {
        lga: "YAKURR",
        chairmen: "OFEM EBRI OBETEN",
      },
      {
        lga: "YALA",
        chairmen: "BARR. FABIAN ODEY OGBECHE",
      },
    ],
  },
  {
    field1: "10",
    state: "DELTA(25)",
    data: [
      { lga: "ANIOCHA NORTH", chairmen: "HON. KEVIN OKWUEJIME" },
      {
        lga: "ANIOCHA SOUTH",
        chairmen: "MR JUDE CHUKWUWIKE",
      },
      {
        lga: "BOMADI",
        chairmen: "MR WILLIAM ANGADI",
      },
      {
        lga: "BURUTU",
        chairmen: "HON. GODKNOWS ANGELE",
      },
      {
        lga: "ETHIOPE EAST",
        chairmen: "MR VICTOR OFOBRUKUTA",
      },
      {
        lga: "ETHIOPE WEST",
        chairmen: "HON OWOSO OGHENEDORO",
      },
      {
        lga: "IKA NORTH EAST",
        chairmen: "BARR VICTOR EBONKA",
      },
      {
        lga: "IKA SOUTH",
        chairmen: "MR SUNNY TATABUZORORGWU",
      },
      {
        lga: "ISOKO NORTH",
        chairmen: "MR CHRISTIAN ITIRE",
      },
      {
        lga: "ISOKO SOUTH",
        chairmen: "MR VICTOR ASASA",
      },
      {
        lga: "NDOKWA EAST",
        chairmen: "JOAN GOVERNOR",
      },
      {
        lga: "NDOKWA WEST",
        chairmen: "MR GODWIN OBI-NZETE",
      },
      {
        lga: "OKPE",
        chairmen: "HON ISAIAH ONORIODE ESIOVWA",
      },
      {
        lga: "OSHIMILI NORTH",
        chairmen: "HON ESEWEZIE INNOCENT",
      },
      {
        lga: "OSHIMILI SOUTH",
        chairmen: "MR OBI KELVIN EZENYILI",
      },
      {
        lga: "PATANI",
        chairmen: "MR ISAAC AGUANA",
      },
      {
        lga: "SAPELE",
        chairmen: "MR EUGENE UNUAGHA",
      },
      {
        lga: "UDU",
        chairmen: "HON. ONAJITE BROWN",
      },
      {
        lga: "UGHELLI NORTH",
        chairmen: "MR GODWIN  ADODE",
      },
      {
        lga: "UGHELLI SOUTH",
        chairmen: "DR RICHARD KOFI",
      },
      {
        lga: "UKWUANI",
        chairmen: "BARR. SOLOMON AJEDE",
      },
      {
        lga: "UVWIE",
        chairmen: "MR RAMSON ONOYAKE",
      },
      {
        lga: "WARRI NORTH",
        chairmen: "MR SMART YEMI  ASEKUTU",
      },
      {
        lga: "WARRI SOUTH",
        chairmen: "MR MICHAEL TIDI",
      },
      {
        lga: "WARRI SOUTH WEST",
        chairmen: "MR DUKE TUOYO",
      },
    ],
  },
  {
    field1: "11",
    state: "EBONYI(13)",
    data: [
      { lga: "ABAKALIKI", chairmen: "EMMANUEL NWANGELE" },
      {
        lga: "AFIKPO NORTH",
        chairmen: "BARR OBIAGERI OKO ENYIM",
      },
      {
        lga: "AFIKPO SOUTH",
        chairmen: "BARR UDUMA CHIMA ENI",
      },
      {
        lga: "EBONYI",
        chairmen: "CHINYERE OGBAGA",
      },
      {
        lga: "EZZA NORTH",
        chairmen: "DR NORAH ALO",
      },
      {
        lga: "EZZA SOUTH",
        chairmen: "ENGR KEN EZE",
      },
      {
        lga: "IKWO",
        chairmen: "ELDER STEVE OROGWU",
      },
      {
        lga: "ISHIELU",
        chairmen: "HENRY EZE",
      },
      {
        lga: "IVO",
        chairmen: "MR ONYEBUCHI AGBADU",
      },
      {
        lga: "IZZI",
        chairmen: "PAUL NWOGHA",
      },
      {
        lga: "OHAOZARA",
        chairmen: "PRINCESS CHINYERE IYIOKU",
      },
      {
        lga: "OHAUKWU",
        chairmen: "BARR CLEMENT ODAH",
      },
      {
        lga: "ONICHA",
        chairmen: "ENGR FELIX IGBOKE",
      },
    ],
  },
  {
    field1: "12",
    state: "EDO(18)",
    data: [
      { lga: "AKOKO-EDO", chairmen: "DON UMORU" },
      {
        lga: "EGOR",
        chairmen: "EGHE OGBEMUDIA",
      },
      {
        lga: "ESAN CENTRAL",
        chairmen: "HON.PROF WAZIRI IDEMUDIA EDOKPA",
      },
      {
        lga: "ESAN NORTH EAST",
        chairmen: "HON AUSTINE OKOIBHOLE",
      },
      {
        lga: "ESAN SOUTH EAST",
        chairmen: "MR PETER AGUELE",
      },
      {
        lga: "ESAN WEST",
        chairmen: "MR PATRICK AGUINEDE",
      },
      {
        lga: "ETSAKO CENTRAL",
        chairmen: "COMR. JOHN OSI AKHIGBE",
      },
      {
        lga: "ETSAKO EAST",
        chairmen: "HON. MRS BENEDICTA EBUECHI",
      },
      {
        lga: "ETASKO WEST",
        chairmen: "MR IMONOFI OSUMAH INUSA",
      },
      {
        lga: "IGUEBEN",
        chairmen: "DR JOSIE OGEDEGBE",
      },
      {
        lga: "IKPOBA-OKHA",
        chairmen: "DR. ERIC OSAYANDE",
      },
      {
        lga: "OREDO",
        chairmen: "HON. EVBAREKE JEKINS OSUNDE",
      },
      {
        lga: "ORHIONMWON",
        chairmen: "HON SYLVESTER OKORO",
      },
      {
        lga: "OVIA NORTH EAST",
        chairmen: "SCOTT OGBEMUDIA",
      },
      {
        lga: "OVIA SOUTH WEST",
        chairmen: "COMR. DESTINY ENABULELE",
      },
      {
        lga: "OWAN EAST",
        chairmen: "MR ANDREW OSIGWE",
      },
      {
        lga: "OWAN WEST",
        chairmen: "MR FRANK IIABOYA",
      },
      {
        lga: "UHUNMWONDE",
        chairmen: "HON. NAPOLEON AGBAMA",
      },
    ],
  },
  {
    field1: "13",
    state: "EKITI(16)",
    data: [
      { lga: "ADO EKITI", chairmen: "HON DEJI OGUNSAKIN" },
      {
        lga: "AIYEKIRE(GBONYIN)",
        chairmen: "HON SADE AKINRINMOLA",
      },
      {
        lga: "EFON",
        chairmen: "HON  BOLAJI JEJE",
      },
      {
        lga: "EKITI EAST",
        chairmen: "HON SAMUEL ADENIYI",
      },
      {
        lga: "EKITI SOUTH WEST",
        chairmen: "HON. LANREWAJU OMOLASE",
      },
      {
        lga: "EKITI WEST",
        chairmen: "HON, KOLAWOLE OMOTUNDE",
      },
      {
        lga: "EMURE",
        chairmen: "HON TAIWO OGUNTUNASE",
      },
      {
        lga: "IDO OSI",
        chairmen: "HON CHIEF. AYODEJI AROGBODO",
      },
      {
        lga: "IJERO",
        chairmen: "HON ABIODUN DADA",
      },
      {
        lga: "IKERE",
        chairmen: "HON BOLA ALONGE",
      },
      {
        lga: "IKOLE",
        chairmen: "HON ADESOLA ADEYANJU",
      },
      {
        lga: "ILEJEMEJE",
        chairmen: "GANIYU BAKARE",
      },
      {
        lga: "IREPODUN/IFELODUN",
        chairmen: "DAPO OLAGUNJU",
      },
      {
        lga: "ISE/ORUN",
        chairmen: "OLUMIDE FALADE",
      },
      {
        lga: "MOBA",
        chairmen: "ADENIYI ADEBAYO",
      },
      {
        lga: "OYE",
        chairmen: "TAYO OGUNDARE",
      },
    ],
  },
  {
    field1: "14",
    state: "ENUGU(17)",
    data: [
      { lga: "ANINRI", chairmen: "HON. EZEKIEL CHUKWU" },
      {
        lga: "AWGU",
        chairmen: "HON. PEDRO OKWUDILI NWANKWO",
      },
      {
        lga: "ENUGU EAST",
        chairmen: "HON. ALEX O. UGWU",
      },
      {
        lga: "ENUGU NORTH",
        chairmen: "HON EMEKA ONUNZE TATA",
      },
      {
        lga: "ENUGU SOUTH",
        chairmen: "HON. MONDAY ENEH",
      },
      {
        lga: "EZEAGU",
        chairmen: "HON. CHUKWUDI M. ANI",
      },
      {
        lga: "IGBO-ETITI",
        chairmen: "HON. MRS NKECHI UGWU-OJU",
      },
      {
        lga: "IGBO-EZE NORTH",
        chairmen: "HON. EJIKE W.ITODO",
      },
      {
        lga: "IGBO-EZE SOUTH",
        chairmen: "PETER ANDY OMEJE",
      },
      {
        lga: "ISI-UZO",
        chairmen: "JACOB ABONYI",
      },
      {
        lga: "NKANU EAST",
        chairmen: "HON. ABEL UCHENNA NWOBODO",
      },
      {
        lga: "NKANU WEST",
        chairmen: "CHIEF NWABUEZE NNAMANI",
      },
      {
        lga: "NSUKKA",
        chairmen: "HON PATRICK K. OMEJE",
      },
      {
        lga: "OJI-RIVER",
        chairmen: "HON. BARR FRANKLIN UDEMEZUE",
      },
      {
        lga: "UDENU",
        chairmen: "HON. SOLOMON I. ONAH",
      },
      {
        lga: "UDI",
        chairmen: "HON. IFEANYI AGU",
      },
      {
        lga: "UZO-UWANI",
        chairmen: "HON. CHUKWUDI NNADOZIE",
      },
    ],
  },
  {
    field1: "15",
    state: "GOMBE(11)",
    data: [
      { lga: "AKKO", chairmen: "GARBA USMAN BARAMBU" },
      {
        lga: "BALANGA",
        chairmen: "GARBA UMAR",
      },
      {
        lga: "BILLIRI",
        chairmen: "MARGARET BITRUS",
      },
      {
        lga: "DUKKU",
        chairmen: "JAMILU AHMED SHABEWA",
      },
      {
        lga: "FUNAKAYE",
        chairmen: "IBRAHIM ADAMU CHELDU",
      },
      {
        lga: "GOMBE",
        chairmen: "ALIYU USMAN HARUNA",
      },
      {
        lga: "KALTUNGO",
        chairmen: "FARUK ALIYU UMAR",
      },
      {
        lga: "KWAMI",
        chairmen: "IBRAHIM BUBA",
      },
      {
        lga: "NAFADA",
        chairmen: "MUSA ABUBAKAR",
      },
      {
        lga: "SHOMGOM",
        chairmen: "YOHANNAH NAHAR",
      },
      {
        lga: "YAMALTU/DEBA",
        chairmen: "SHUAIBU UMAR GALADIMA",
      },
    ],
  },
  {
    field1: "16",
    state: "IMO(27)",
    data: [
      { lga: "ABOH-MBAISE", chairmen: "BARR. EMMA NJOKU" },
      {
        lga: "AHIAZU-MBAISE",
        chairmen: "CHIEF LARRY OBINNA CHIKWE",
      },
      {
        lga: "EHIME-MBANO",
        chairmen: "BARR. CHIDI OKECHUKWU",
      },
      {
        lga: "EZINIHITE MBAISE",
        chairmen: "CHIEF FRANCIS ONYEBERECHI",
      },
      {
        lga: "IDEATO NORTH",
        chairmen: "GLORIA MBADIGWE",
      },
      {
        lga: "IDEATO SOUTH",
        chairmen: "CHUKWUMA IKEAKA",
      },
      {
        lga: "IHITE/UBOMA",
        chairmen: "OLEMGBA CHIKE",
      },
      {
        lga: "IKEDURU",
        chairmen: "MR.TONY JUDE IWUAMADI",
      },
      {
        lga: "ISIALA MBANO",
        chairmen: "CHIKA OKOROIKE",
      },
      {
        lga: "ISU",
        chairmen: "KENNETH UKA",
      },
      {
        lga: "MBAITIOLI",
        chairmen: "JOHN EKE",
      },
      {
        lga: "NGOR-OKPALA",
        chairmen: "MAYOR OBINNA NWEKE",
      },
      {
        lga: "NJABA",
        chairmen: "EMEKA IHEANACHO",
      },
      {
        lga: "NWANGELE",
        chairmen: "COSMOS IWUAGWU",
      },
      {
        lga: "NKWERRE",
        chairmen: "FRANK ONYEJIAKA",
      },
      {
        lga: "OBOWO",
        chairmen: "HON C.C IHEJIRIKE",
      },
      {
        lga: "OGUTA",
        chairmen: "HON. EMMA MAZI",
      },
      {
        lga: "OHAJI/EGBEMA",
        chairmen: "AMADIOHA MARCEL",
      },
      {
        lga: "OKIGWE",
        chairmen: "MRS NKECHI MBAONU",
      },
      {
        lga: "ORLU",
        chairmen: "MRS .IFEOMA NDUPU",
      },
      {
        lga: "ORSU",
        chairmen: "HON .UCHENNE OSIGWE",
      },
      {
        lga: "ORU EAST",
        chairmen: "NZE NYERERE OGBONNA",
      },
      {
        lga: "ORU WEST",
        chairmen: "WILLIE OKOLIEGWO",
      },
      {
        lga: "OWERRI-MUNICIPAL",
        chairmen: "AMBROSE  NKWODINMA",
      },
      {
        lga: "OWERRI NORTH",
        chairmen: "ENGR LAMS IBEH]",
      },
      {
        lga: "OWERRI WEST",
        chairmen: "ENYIOHA OKECHUKWU",
      },
      {
        lga: "ONUIMO",
        chairmen: "CHARLES MATTHEW IJEZIE",
      },
    ],
  },
  {
    field1: "17",
    state: "JIGAWA(27)",
    data: [
      { lga: "AUYO", chairmen: "ALHAJI UMAR MUSA KALGWAI" },
      {
        lga: "BABURA",
        chairmen: "MUHAMMED IBRAHIM",
      },
      {
        lga: "BIRNIN KUDU",
        chairmen: "WADA FAKA",
      },
      {
        lga: "BIRNIWA",
        chairmen: "MUHAMMED JAJI DOLE",
      },
      {
        lga: "BUJI",
        chairmen: "HUDU BABANGIDA",
      },
      {
        lga: "DUTSE",
        chairmen: "BALA YAKUBU",
      },
      {
        lga: "GAGARAWA",
        chairmen: "IBRAHIM YA'U",
      },
      {
        lga: "GARKI",
        chairmen: "GHALI MUKTAR",
      },
      {
        lga: "GUMEL",
        chairmen: "AMINU SANI",
      },
      {
        lga: "GURI",
        chairmen: "BARKONO JAJI ADIYANI",
      },
      {
        lga: "GWARAM",
        chairmen: "MUHAMMED DAN ASABE",
      },
      {
        lga: "GWIWA",
        chairmen: "SALE AHMED ZAUMA",
      },
      {
        lga: "HADEJIA",
        chairmen: "ABDULLAHI MAI KANTI",
      },
      {
        lga: "JAHUN",
        chairmen: "MAGAJI INUWA",
      },
      {
        lga: "KAFIN HAUSA",
        chairmen: "GARBA ABDULLAHI",
      },
      {
        lga: "KAUGAMA",
        chairmen: "AHMED YAHAYA MARKE",
      },
      {
        lga: "KAZAURE",
        chairmen: "JAMILU AWAISU ZAKI",
      },
      {
        lga: "KIRI KASAMMA",
        chairmen: "SALISU GARBA KUBAYO",
      },
      {
        lga: "KIYAWA",
        chairmen: "ISYAKU ADAMU",
      },
      {
        lga: "MAIGATARI",
        chairmen: "SANI DAHIRU",
      },
      {
        lga: "MALAM MADORI",
        chairmen: "USAINI UMARI",
      },
      {
        lga: "MIGA",
        chairmen: "MUHAMMED A ABBA",
      },
      {
        lga: "RINGIM",
        chairmen: "ABDULRASHID ILLA",
      },
      {
        lga: "RONI",
        chairmen: "SALISU SANI",
      },
      {
        lga: "SULE TANKARKAR",
        chairmen: "JAFARU MUHAMMED",
      },
      {
        lga: "TAURA",
        chairmen: "BAFFA YAHAYA TAURA",
      },
      {
        lga: "YANKWASHI",
        chairmen: "DAUDA DAN AUWA",
      },
    ],
  },
  {
    field1: "18",
    state: "KADUNA(23)",
    data: [
      { lga: "BIRNIN-GWARI", chairmen: "ALHAJI GAMBO GARBA" },
      {
        lga: "CHIKUN",
        chairmen: "HON SAMAILA LEEMAN",
      },
      {
        lga: "GIWA",
        chairmen: "HON SHEHU BALARABE",
      },
      {
        lga: "IGABI",
        chairmen: "HON JABIR KHAMIS RIGASA",
      },
      {
        lga: "IKARA",
        chairmen: "HON BENJAMIN JOCK",
      },
      {
        lga: "JABA",
        chairmen: "HADIZA USMAN MU`AZU",
      },
      {
        lga: "JEMA`A",
        chairmen: "HON PETER DANJUMA AVERIK",
      },
      {
        lga: "KACHIA",
        chairmen: "HON PETER AGITE",
      },
      {
        lga: "KADUNA NORTH",
        chairmen: "SALEH SHUAIBU",
      },
      {
        lga: "KADUNA SOUTH",
        chairmen: "YAKUBU JARIMI",
      },
      {
        lga: "KAGARKO",
        chairmen: "ADAMU SHUAIBU",
      },
      {
        lga: "KAJURU",
        chairmen: "CAFRA CAINO",
      },
      {
        lga: "KAURA",
        chairmen: "DR KUTUKA BEGE AYUBA",
      },
      {
        lga: "KAURU",
        chairmen: "ALHAJI SHUAIBU GOMA",
      },
      {
        lga: "KUBAU",
        chairmen: "ALHAJI SABO AMINU ANCHAU",
      },
      {
        lga: "KUDAN",
        chairmen: "HON SHUAIBU BAWA JAJA",
      },
      {
        lga: "LERE",
        chairmen: "HON ABUBAKAR BUBA",
      },
      {
        lga: "MAKARFI",
        chairmen: "KABIRU MA`AZU MAYERE",
      },
      {
        lga: "SABON GARI",
        chairmen: "ENGR MOHAMMED USMAN",
      },
      {
        lga: "SANGA",
        chairmen: "HON SAMUEL SHAMAKI",
      },
      {
        lga: "SOBA",
        chairmen: "HON. ALH MUHAMMED MAHMOUD",
      },
      {
        lga: "ZANGON-KATAF",
        chairmen: "DR. ELIAS MANZA",
      },
      {
        lga: "ZARIA",
        chairmen: "ALIYU IDRIS IBRAHIM",
      },
    ],
  },
  {
    field1: "19",
    state: "KANO(44)",
    data: [
      { lga: "AJINGI", chairmen: "ALHAJI WADA ALIYU HAKIMIN" },
      {
        lga: "ALBASU",
        chairmen: "AMINU HUSSAIN HUNGU",
      },
      {
        lga: "BAGWAI",
        chairmen: "ALHAJI ADO ISYAKU DADDAUDA",
      },
      {
        lga: "BEBEJI",
        chairmen: "DANKAKA HUSSAINI",
      },
      {
        lga: "BICHI",
        chairmen: "HON SANI MUKADDAS",
      },
      {
        lga: "BUNKURE",
        chairmen: "HON ABBS KAWU GURJIYA",
      },
      {
        lga: "DALA",
        chairmen: "ALH IBRAHIM SULEMAN",
      },
      {
        lga: "DAMIBATTA",
        chairmen: "IDRIS HARUNA ZAGO",
      },
      {
        lga: "DAWAKIN KUDU",
        chairmen: "ALHAJI YUSUF BAYERO",
      },
      {
        lga: "DAWAKIN TOFA",
        chairmen: "ALHAJI ADO TAMBAI",
      },
      {
        lga: "DOGUWA",
        chairmen: "SHUIBU UMAR",
      },
      {
        lga: "FAGGE",
        chairmen: "HON. IBRAHIM MUHAMMED ABDUALLHI",
      },
      {
        lga: "GABASAWA",
        chairmen: "RABIU ADAMU GARUN",
      },
      {
        lga: "GARKO",
        chairmen: "MAIGIDA ADAMU DAL",
      },
      {
        lga: "GARUM MALLAM",
        chairmen: "ALHAJI DANIYA IBRAHIM",
      },
      {
        lga: "GAYA",
        chairmen: "HON AHMED T. ABDULLAHI",
      },
      {
        lga: "GEZAWA",
        chairmen: "HUDY USMAN ZAINAWA",
      },
      {
        lga: "GWALE",
        chairmen: "HON KHALID ISHAQ DISO",
      },
      {
        lga: "GWARZO",
        chairmen: "ENGR BASHIR ABDULLAHI",
      },
      {
        lga: "KABO",
        chairmen: "MUSTAPHA INUWA GARO",
      },
      {
        lga: "KANO MUNICIPAL",
        chairmen: "HON SABO MUHAMMED DANTATA",
      },
      {
        lga: "KARAYE",
        chairmen: "ALHAJI MUSA MUHAMMED INUWA",
      },
      {
        lga: "KIBIYA",
        chairmen: "HON ABUBAKAR RABIU",
      },
      {
        lga: "KIRU",
        chairmen: "ALHAJI NASIRU MUAZU",
      },
      {
        lga: "KUMBOTSO",
        chairmen: "HASSAB GARBAN KAUYE",
      },
      {
        lga: "KUNCHI",
        chairmen: "HON. AMINU IDI SHUWAKI",
      },
      {
        lga: "KURA",
        chairmen: "NURADEEN HURUNA RIMINGADO",
      },
      {
        lga: "MADOBI",
        chairmen: "BARR BASHIR YUSUF",
      },
      {
        lga: "MAKODA",
        chairmen: "HAMISU CHIDARI",
      },
      {
        lga: "MINJIBIR",
        chairmen: "HON MURTALA SULE",
      },
      {
        lga: "NASARAWA",
        chairmen: "DR. LAMIN SANI",
      },
      {
        lga: "RANO",
        chairmen: "ALHAJI AUWALU ABDULLAHI",
      },
      {
        lga: "RIMIN GADO",
        chairmen: "MUHUYI MAGAJI",
      },
      {
        lga: "ROGO",
        chairmen: "HON MUBARAK BASHIR BELLO",
      },
      {
        lga: "SHANONO",
        chairmen: "ALI IBRAHIM ISA",
      },
      {
        lga: "SUMAILA",
        chairmen: "ABDULHAMID IDRIS RIMI",
      },
      {
        lga: "TAKAI",
        chairmen: "MUHAMMED BAPPA",
      },
      {
        lga: "TARAUNI",
        chairmen: "HON MUAZZAM IBRAHIM MAINSARA",
      },
      {
        lga: "TOFA",
        chairmen: "ALHAJI NAFIU H GARBA",
      },
      {
        lga: "TSANYAWA",
        chairmen: "HON KABIRU SULAIMAN DUMBULUN",
      },
      {
        lga: "TUDUN WADA",
        chairmen: "HON AHMED TIJANI MUSAH",
      },
      {
        lga: "UNGOGO",
        chairmen: "ENGR. ABDUALLAH GARBA RAMAT",
      },
      {
        lga: "WARAWA",
        chairmen: "HON. ALHAJI YUSUF ABDULLAHI DANLASAN",
      },
      {
        lga: "WUDIL",
        chairmen: "ALHAJI MUNTARI GARBA",
      },
    ],
  },
  {
    field1: "20",
    state: "KATSINA(34)",
    data: [
      { lga: "BAKORI", chairmen: "ALHAJI AMINU MAGAJ" },
      {
        lga: "BATAGARAWA",
        chairmen: "HON ABUBAKAR A TSANNI",
      },
      {
        lga: "BATSARI",
        chairmen: "LAWAL MUSA",
      },
      {
        lga: "BUARE",
        chairmen: "ALHAJI MANNIR YABUKU",
      },
      {
        lga: "BINDAWA",
        chairmen: "LAWAL JARI TAMA",
      },
      {
        lga: "CHARANCHI",
        chairmen: "ABDULKUDUSU HARUNA",
      },
      {
        lga: "DANDUME",
        chairmen: "HON. YA`U AHMED",
      },
      {
        lga: "DAURA",
        chairmen: "ENGR MALAM ABBA",
      },
      {
        lga: "DAN MUSA",
        chairmen: "N.M DANSUMA",
      },
      {
        lga: "DANJA",
        chairmen: "HON IBRAHIM BASIRU",
      },
      {
        lga: "DUTSI",
        chairmen: "TASIU SULEMAN",
      },
      {
        lga: "DUSTIN-MA",
        chairmen: "IBRAHIM SHEMA",
      },
      {
        lga: "FASKARI",
        chairmen: "ALHAJI BALA FASKARI",
      },
      {
        lga: "FUNTUA",
        chairmen: "ALHAJI LAWAL SANI MATAZU",
      },
      {
        lga: "INGAWA",
        chairmen: "ALHAJI IBRAHIM MAMMAN",
      },
      {
        lga: "JIBIA",
        chairmen: "HON SALISU YUSUF",
      },
      {
        lga: "KAFUR",
        chairmen: "HON MUSA IDRIS",
      },
      {
        lga: "KAITA",
        chairmen: "ENGR BELLO LAWAL YANDAKI",
      },
      {
        lga: "KANKARA",
        chairmen: "MUHAMMADU LAWAL AREDA",
      },
      {
        lga: "KANKIA",
        chairmen: "MURSHIDDINN SULIEMAN",
      },
      {
        lga: "KATSINA",
        chairmen: "HAMISU GAMBO",
      },
      {
        lga: "KURFI",
        chairmen: "NURA A KUFRI",
      },
      {
        lga: "KUSADA",
        chairmen: "BALA IDRIS",
      },
      {
        lga: "MAL`ADUA",
        chairmen: "AG. MUSA KALLA",
      },
      {
        lga: "MALUMFASHI",
        chairmen: "DR AMINU GARBA WAZIRI",
      },
      {
        lga: "MANI",
        chairmen: "ALIYU HARUNA JANI",
      },
      {
        lga: "MASHI",
        chairmen: "SURAJO MUHAMMED",
      },
      {
        lga: "MATAZU",
        chairmen: "ABDU DANSHEHU",
      },
      {
        lga: "MUSAWA",
        chairmen: "LAWAL ALIYU MUSAWA",
      },
      {
        lga: "RIMI",
        chairmen: "NASIRU ALA IYATAWA",
      },
      {
        lga: "SABUWA",
        chairmen: "ALHAJI LAWAL DAKO",
      },
      {
        lga: "SAFANA",
        chairmen: "ABBA DAYYABU SAFANA",
      },
      {
        lga: "SANDAMU",
        chairmen: "SANI SABO SANDAMU",
      },
      {
        lga: "ZANGO",
        chairmen: "ADAMU MATO",
      },
    ],
  },
  {
    field1: "21",
    state: "KEBBI(21)",
    data: [
      { lga: "ALEIRO", chairmen: "PROF. IBRAHIM HUSSAINI" },
      {
        lga: "AREWA-DANDI",
        chairmen: "DR. MUHAMMED AMINU BUI",
      },
      {
        lga: "ARGUNGU",
        chairmen: "PROF. RABI MUHAMMED",
      },
      {
        lga: "AUGIE",
        chairmen: "PROF. MUHD AMINU BAYAWA",
      },
      {
        lga: "BAGUDO",
        chairmen: "PROF. IBRAHIM MUHD BANDI ZAGGA",
      },
      {
        lga: "BIRNIN KEBBI",
        chairmen: "PROF. UMAR ALIYU CHIKA",
      },
      {
        lga: "BUNZA",
        chairmen: "PROF. ALIYU MUHD BUNZA",
      },
      {
        lga: "DANDI",
        chairmen: "DR. SAMAILA KAMBA",
      },
      {
        lga: "FAKAI",
        chairmen: "PROF. MUHD GARBA MUHUTA",
      },
      {
        lga: "GWANDU",
        chairmen: "DR. MUHD SHALLA BELLO",
      },
      {
        lga: "JEGA",
        chairmen: "PROF. SULEIMAN KHALID",
      },
      {
        lga: "KALGO",
        chairmen: "PROF. FARUKU ALIYU KALGO",
      },
      {
        lga: "KOKO/BESSE",
        chairmen: "PROF ABUBAKAR DAN BABA",
      },
      {
        lga: "MAIYAMA",
        chairmen: "DR. BALARABE ISAH ADAMU",
      },
      {
        lga: "NGASKI",
        chairmen: "PROF. UMAR ABUBAKAR B/YAURI",
      },
      {
        lga: "SAKABA",
        chairmen: "PROF SANUSI MUHD",
      },
      {
        lga: "SHANGA",
        chairmen: "DR. ABDULBASIT AHMED ATUWO",
      },
      {
        lga: "SURU",
        chairmen: "DR. SODANGI UMAR",
      },
      {
        lga: "WASAGU/DANKO",
        chairmen: "DR. NASIR MUHD BABA",
      },
      {
        lga: "YAURI",
        chairmen: "PROF. SODIQ ABDULLAHI YELWA",
      },
      {
        lga: "ZURU",
        chairmen: "DR. SANI DANTANI MANGA",
      },
    ],
  },
  {
    field1: "22",
    state: "KOGI(21)",
    data: [
      { lga: "ADAVI", chairmen: "MR JOSEPH SALAMI" },
      {
        lga: "AJAOKUTA",
        chairmen: "MR MUSTAPHA AKAABA",
      },
      {
        lga: "ANKPA",
        chairmen: "MR IBRAHIM ABAGWU",
      },
      {
        lga: "BASSA",
        chairmen: "MR MOHAMMED SHUIABU",
      },
      {
        lga: "DEKINA",
        chairmen: "MR SHAIBU SIAKA",
      },
      {
        lga: "IBAJI",
        chairmen: "MR WILLIAM OBIORA",
      },
      {
        lga: "IDAH",
        chairmen: "MR ABU ODOMA",
      },
      {
        lga: "IGALAMELA-ODOLU",
        chairmen: "MR JAMES ONOJA",
      },
      {
        lga: "IJUMU",
        chairmen: "MR TAOFIQ ISAH",
      },
      {
        lga: "KABBA/BUNU",
        chairmen: "HON MOSES OLORUNLEKE",
      },
      {
        lga: "KOTONKARFE",
        chairmen: "HON OHIMEGYE IGU",
      },
      {
        lga: "LOKOJA",
        chairmen: "MR MOHAMMED DAN ASABE",
      },
      {
        lga: "MOPA-MURO",
        chairmen: "MR MOSES DAVID",
      },
      {
        lga: "OFU",
        chairmen: "MR IBRAHIM AMODU",
      },
      {
        lga: "OGORI-MAGONGO",
        chairmen: "MR GOKE OPARISON",
      },
      {
        lga: "OKEHI",
        chairmen: "MR ABDULRAHEEM OHIARE",
      },
      {
        lga: "OKENE",
        chairmen: "MR ABDULRAZAK YUSUF",
      },
      {
        lga: "OLAMABOLO",
        chairmen: "MR FRIDAY OLAMABORO",
      },
      {
        lga: "OMALA",
        chairmen: "MR IBRAHIM ABOH",
      },
      {
        lga: "YAGBA EAST",
        chairmen: "MR ASIRU ABDULRAZAK",
      },
      {
        lga: "YAGBA WEST",
        chairmen: "MR KOLAWOLE PIUS",
      },
    ],
  },
  {
    field1: "23",
    state: "KWARA(16)",
    data: [
      { lga: "ASA", chairmen: "GANI SAADU" },
      {
        lga: "BARUTEN",
        chairmen: "ABDULRASHEED LAFIA",
      },
      {
        lga: "EDU",
        chairmen: "MUHAMMED ALIYU",
      },
      {
        lga: "EKITI",
        chairmen: "KEHINDE BOYEDE",
      },
      {
        lga: "IFELODUN",
        chairmen: "JIDE ASHONIBARE",
      },
      {
        lga: "ILORIN SOUTH",
        chairmen: "ALHAJI MONSURU ADEMOLA",
      },
      {
        lga: "ILORIN EAST",
        chairmen: "HAJIA AYOBAMI SERIKI",
      },
      {
        lga: "ILORIN WEST",
        chairmen: "ABDULGAFAR TOSHO",
      },
      {
        lga: "IREPODUN",
        chairmen: "JIDE OYINLOYE",
      },
      {
        lga: "ISIN",
        chairmen: "TIMOTHY OLATUNDE FADIPE",
      },
      {
        lga: "KAIAMA",
        chairmen: "SULAIMAN GADO",
      },
      {
        lga: "MORO",
        chairmen: "HON ISIAKA ALIKINLA",
      },
      {
        lga: "OFFA",
        chairmen: "JARE OLATUNDUN",
      },
      {
        lga: "OKE-ERO",
        chairmen: "VICTOR GBENGA YUSUF",
      },
      {
        lga: "OYUN",
        chairmen: "WAHEED IBRAHIM IYANDA",
      },
      {
        lga: "PATEGI",
        chairmen: "ALHAJI UMAR BOLOGI",
      },
    ],
  },
  {
    field1: "24",
    state: "LAGOS(20)",
    data: [
      { lga: "ALIMOSHO", chairmen: "SULAIMON JELILI" },
      {
        lga: "AJEROMI-IFELODUN",
        chairmen: "AYOOLA FATAI ADEKUNLE",
      },
      {
        lga: "KOSOFE",
        chairmen: "AFOLABI B SOFOLA",
      },
      {
        lga: "MUSHIN",
        chairmen: "EMMANUEL BAMIGBOYE",
      },
      {
        lga: "OSHODI-ISOLO",
        chairmen: "BOLAGI ARIYOH",
      },
      {
        lga: "OJO",
        chairmen: "IDOWU RASAULU OLUSOLA",
      },
      {
        lga: "IKORODU",
        chairmen: "ADESINA WASIU",
      },
      {
        lga: "SURULERE",
        chairmen: "TAJUDEEN AJIDE YUSSUFF",
      },
      {
        lga: "AGEGE",
        chairmen: "EGUNJOBI GANIU KOLA",
      },
      {
        lga: "IFAKO-IJAIYE",
        chairmen: "APOSTLE OLORUNTOBA OKE",
      },
      {
        lga: "SOMOLU",
        chairmen: "ABDUL HAMMED SALAWU",
      },
      {
        lga: "AMUWO-ODOFIN",
        chairmen: "BURAIMOH VALENTINE",
      },
      {
        lga: "LAGOS MAINLAND",
        chairmen: "ESSIEN OMOTOLA RASHIDAT",
      },
      {
        lga: "IKEJA",
        chairmen: "ENGR MOJEED ALABI BALOGUN",
      },
      {
        lga: "ETI-OSA",
        chairmen: "BANKOLE ADESEGUN SAHEED",
      },
      {
        lga: "BADAGRY",
        chairmen: "ADENIRAN OLUSEGUN ONILUDE",
      },
      {
        lga: "APAPA",
        chairmen: "ADELE ELIJAH OWOLABI",
      },
      {
        lga: "LAGOS ISLAND",
        chairmen: "ADETOYESE OLUSI",
      },
      {
        lga: "EPE",
        chairmen: "DOYIN ADESANYA",
      },
      {
        lga: "IBEJU-LEKKI",
        chairmen: "SURAKAT SEFIU OLORUNKEMI",
      },
    ],
  },

  {
    field1: "",
    state: "LAGOS LCDA(37)",
    data: [
      { lga: "ORILE AGEGE(LCDA)", chairmen: "JOHNSON S. BABATUNDE" },
      {
        lga: "IFELODUN(LCDA)",
        chairmen: "AJIDAGBA FAITH",
      },
      {
        lga: "AGBADO OKE ODO(LCDA)",
        chairmen: "AROGUN AUGUSTINE A",
      },
      {
        lga: "AYOBO IPAJA(LCDA)",
        chairmen: "YUSUF SIKIRU ADISA",
      },
      {
        lga: "EGBE IDIMU(LCDA)",
        chairmen: "KUNLE SANYA OLU",
      },
      {
        lga: "IGANDO IKOTUN(LCDA)",
        chairmen: "MORENIKE A ADESINA WILLIAMS",
      },
      {
        lga: "MOSAN-OKUNOLA(LCDA)",
        chairmen: "MRS .ADEBAJO OLABISI D",
      },
      {
        lga: "ORIADE(LCDA)",
        chairmen: "AKINLOLA HASSAN RAMOTALAI",
      },
      {
        lga: "APAPA IGANMU(LCDA)",
        chairmen: "FUNMILAYO AKANDE MUHAMMED",
      },
      {
        lga: "BADAGRY WEST(LCDA)",
        chairmen: "GBENU HENNUGBE JOSEPH",
      },
      {
        lga: "OLORUNDA(LCDA)",
        chairmen: "SAMSON FOLORUNSO OLATUNDE",
      },
      {
        lga: "EREDO(LCDA)",
        chairmen: "SALIU ADENIYI RASAQ",
      },
      {
        lga: "IKOSI EJINRIN(LCDA)",
        chairmen: "ONANUGA ADENIYI SAMSON",
      },
      {
        lga: "ETI-OSA EAST(LCDA)",
        chairmen: "OLUFUNMI RAFIU OLATUNJI",
      },
      {
        lga: "IRU VICTORIA ISLAND(LCDA)",
        chairmen: "PRINCESS RASHEEDAT ABIODUN ADU",
      },
      {
        lga: "IKOYI OBALENDE(LCDA)",
        chairmen: "FUAD ATANDA LAWAL",
      },
      {
        lga: "LEKKI(LCDA)",
        chairmen: "OGIDAN MUKANDASI OLAITAN",
      },
      {
        lga: "AGBOYI-KETU(LCDA)",
        chairmen: "MAYOR DELE OSHINOWO",
      },
      {
        lga: "IKOSI-ISHERI(LCDA)",
        chairmen: "PRINCESS SAMIAT ABOLANLE BADA",
      },
      {
        lga: "OJOKORO(LCDA)",
        chairmen: "HAMMED I. TIJANI",
      },
      {
        lga: "OJODU(LCDA)",
        chairmen: "ENGR JULIUS OLORO",
      },
      {
        lga: "ONIGBONGBO(LCDA)",
        chairmen: "BABATUNDE OKE",
      },
      {
        lga: "IKORODU NORTH(LCDA)",
        chairmen: "BANJO ADEOLA A",
      },
      {
        lga: "IKORODU WEST(LCDA)",
        chairmen: "PRINCESS JUMOKE ADEMEHIN",
      },
      {
        lga: "IMOTA(LCDA)",
        chairmen: "PRINCE AGORO KUNLE WASIU",
      },
      {
        lga: "IJEDE(LCDA)",
        chairmen: "SALISU FATIU IMOH",
      },
      {
        lga: "IGBOGBO-BAIYEKU(LCDA)",
        chairmen: "DAINI OLUSESAN MAYORKUN",
      },
      {
        lga: "LAGOS ISLAND EAST(LCDA)",
        chairmen: "COMR. KAML O. SALAU-BASHUA",
      },
      {
        lga: "YABA(LCDA)",
        chairmen: "OMIYALE KAYODE",
      },
      {
        lga: "ODI-OLOWO(LCDA)",
        chairmen: "AJALA RASAQ",
      },
      {
        lga: "OTO AWORI(LCDA)",
        chairmen: "PRONCE MUSIBAU ADEKUNLE ASAFA",
      },
      {
        lga: "IBA(LCDA)",
        chairmen: "ADEDAYO RAMAT OSENI",
      },
      {
        lga: "BARIGA(LCDA)",
        chairmen: "ALABI KOLADE DAVID",
      },
      {
        lga: "EJIGBO(LCDA)",
        chairmen: "BELLO MONSURU OLOYEDE",
      },
      {
        lga: "ISOLO(LCDA)",
        chairmen: "OLALEYE SHAMSIDEEN ABIODUN",
      },
      {
        lga: "COKER AGUDA(LCDA)",
        chairmen: "AKINYEMI-OBE OMOBOLANLE M.",
      },
      {
        lga: "ITIRE-IKATE(LCDA)",
        chairmen: "AHMED OLANREWAJU APATIRA",
      },
      {
        field1: "25",
        state: "NASARAWA( 13)",
        lga: "AKWANGA",
        chairmen: "HON SAMUEL N. MESHI",
      },
      {
        lga: "AWE",
        chairmen: "ALHAJI UMAR TANKO",
      },
      {
        lga: "DOMA",
        chairmen: "RABO SANI",
      },
      {
        lga: "KARU",
        chairmen: "HON SAM AKALA",
      },
      {
        lga: "KEANA",
        chairmen: "ENGR ABDULLAHI SULE",
      },
      {
        lga: "KEFFI",
        chairmen: "HON. ABDULRAHMAN SANI MAIGORO",
      },
      {
        lga: "KOKONA",
        chairmen: "MR SAIDU KURKI",
      },
      {
        lga: "LAFIA",
        chairmen: "ALH. AMINU MU`AZU MAIFATA",
      },
      {
        lga: "NASARAWA",
        chairmen: "JOHN MAMMAN",
      },
      {
        lga: "NASARAWA-EGGON",
        chairmen: "ALHAJI DANLAMI IDRIS ODASKO",
      },
      {
        lga: "OBI",
        chairmen: "MUHAMMAD OYIMUGAH",
      },
      {
        lga: "TOTO",
        chairmen: "PRINCE NUHU ADAMU DAUDA",
      },
      {
        lga: "WAMBA",
        chairmen: "MR ABUBAKAR ABAS",
      },
    ],
  },
  {
    field1: "26",
    state: "NIGER(25)",
    data: [
      { lga: "AGAIE", chairmen: "CAPT. HASSAN BARO" },
      {
        lga: "AGWARA",
        chairmen: "ALHAJI NURA MOHAMMED",
      },
      {
        lga: "BIDA",
        chairmen: "SHESHI SULEIMAN",
      },
      {
        lga: "BORGU",
        chairmen: "ALIYU UMAR WARA",
      },
      {
        lga: "BOSSO",
        chairmen: "HON .ARC ABUBAKAR GOMNA SULEIMAN",
      },
      {
        lga: "CHANCHANGA",
        chairmen: "HON IBRAHIM ABUBAKAR BOSSO",
      },
      {
        lga: "EDATI",
        chairmen: "ISAH LIMAN KANTIGI",
      },
      {
        lga: "GBAKO",
        chairmen: "HUSSAINI LEMU",
      },
      {
        lga: "GURARA",
        chairmen: "MR YUSSUF WALI GWAMNA",
      },
      {
        lga: "KATCHA",
        chairmen: "HON. MOHAMMED BABANNA",
      },
      {
        lga: "KONTAGORA",
        chairmen: "ALHAJI AHMAD ATTAHIRU",
      },
      {
        lga: "LAPAI",
        chairmen: "ABDULLAHI A UMAR",
      },
      {
        lga: "LAVUN",
        chairmen: "ALHAJI YAKUBU K ISAH",
      },
      {
        lga: "MAGAMA",
        chairmen: "IDRIS ALIYU BABANGIDA",
      },
      {
        lga: "MARIGA",
        chairmen: "SHEHU HARUNA",
      },
      {
        lga: "MASHEGU",
        chairmen: "SHUAIBU HARUNA KULHO",
      },
      {
        lga: "MOKWA",
        chairmen: "ABUBAKR YAHAYA SHETI",
      },
      {
        lga: "MUNYA",
        chairmen: "ABDULLAHI MOH'D RICCO",
      },
      {
        lga: "PAIKORO",
        chairmen: "YOHANNAH YAKUBU",
      },
      {
        lga: "RAFI",
        chairmen: "GAMBO ABUBAKAR BOJO",
      },
      {
        lga: "RIJAU",
        chairmen: "NASIRU B SAMBO",
      },
      {
        lga: "SHIRORO",
        chairmen: "SULEIMAN DAUDA CHUKUBA",
      },
      {
        lga: "SULEJA",
        chairmen: "ALHAJI ABDULLAHI MAJI",
      },
      {
        lga: "TAFA",
        chairmen: "HO IBRAHIM MAMI IJAH",
      },
      {
        lga: "WUSHISHI",
        chairmen: "ALHAJI DANJUMA NALANGO",
      },
    ],
  },
  {
    field1: "27",
    state: "OGUN(20)",
    data: [
      { lga: "ABEOKUTA NORTH", chairmen: "FOLARIN SURAJ OLAKUNLE" },
      {
        lga: "ABEOKUTA SOUTH",
        chairmen: "LUKMAN SONEKAN",
      },
      {
        lga: "ADO-ODO/OTA",
        chairmen: "DELE ADENIJI",
      },
      {
        lga: "YEWA NORTH",
        chairmen: "ADEAGBO RASAQ A.",
      },
      {
        lga: "YEWA SOUTH",
        chairmen: "JUSTIN OBAFEMI EDUN",
      },
      {
        lga: "EWEKORO",
        chairmen: "KAFARU BALOGUN",
      },
      {
        lga: "IFO",
        chairmen: "HON FOLA SALAMI",
      },
      {
        lga: "IJEBU EAST",
        chairmen: "IYABO BAKARE",
      },
      {
        lga: "IJEBU NORTH",
        chairmen: "BAYO ADEKOYA",
      },
      {
        lga: "IJEBU NORTH-EAST",
        chairmen: "KOLAWOLE S. ONAFUYE",
      },
      {
        lga: "IJEBU ODE",
        chairmen: "GLOLADE OLA OLADUNWO",
      },
      {
        lga: "IKENNE",
        chairmen: "AKINSANYA ROTIMI FATAI",
      },
      {
        lga: "IMEKO-AFON",
        chairmen: "TOSIN ADELUYI",
      },
      {
        lga: "IPOKIA",
        chairmen: "HON. ABDUL KABIR ABOLURIN",
      },
      {
        lga: "OBAFEMI-OWODE",
        chairmen: "JAMIU BALOGUN",
      },
      {
        lga: "OGUN WATERSIDE",
        chairmen: "ONANUGA OLUFEMI SUNDAY",
      },
      {
        lga: "ODEDA",
        chairmen: "SEMIU BOLA LAWAL",
      },
      {
        lga: "ODOGBOLU",
        chairmen: "WALE SHITTU",
      },
      {
        lga: "REMO NORTH",
        chairmen: "SOWOLE AWOYEMI OLATAYO",
      },
      {
        lga: "SHAGAMU",
        chairmen: "KAFARU FEMI FELIX",
      },
    ],
  },
  {
    field1: "28",
    state: "ONDO(18)",
    data: [
      { lga: "AKOKO NORTHEAST", chairmen: "HON ABDULKADIR ASIMIYU ADEKUNLE" },
      {
        lga: "AKOKO NORTHWEST",
        chairmen: "ELDER AYODELE AKANDE",
      },
      {
        lga: "AKOKO SOUTHEAST",
        chairmen: "PAUL ADORUJOYE",
      },
      {
        lga: "AKOKO SOUTHWEST",
        chairmen: "AUGUSTINE OLATUNJI",
      },
      {
        lga: "AKURE NORTH",
        chairmen: "BANKOLE OGBESETOYE",
      },
      {
        lga: "AKURE SOUTH",
        chairmen: "GBENGA OLANIYI",
      },
      {
        lga: "ESE ODO",
        chairmen: "FOLAWOLE SIBASI",
      },
      {
        lga: "IDANRE",
        chairmen: "HON. KAYODE AROLOYE",
      },
      {
        lga: "IFEDORE",
        chairmen: "HON ALEX OLADIMEJI",
      },
      {
        lga: "ILAJE",
        chairmen: "OLAMIGOKE OLATUWASE",
      },
      {
        lga: "ILE OLUJI-OKEIGBO",
        chairmen: "AKISUROJE TEMITAYO",
      },
      {
        lga: "IRELE",
        chairmen: "HON.(BARR) AJIMOTOKIN OLUSEGUN PHILIP",
      },
      {
        lga: "ODIGBO",
        chairmen: "MRS MARGARET AKINSUROJU",
      },
      {
        lga: "OKITIPUPA",
        chairmen: "AKINRINWA IGBEKELE",
      },
      {
        lga: "ONDO EAST",
        chairmen: "HON AKINKUOTU AUGUSTINE WALE",
      },
      {
        lga: "ONDO WEST",
        chairmen: "AKINSULIRE EBENEZER",
      },
      {
        lga: "OSE",
        chairmen: "DR. ANTHONY MODUPE",
      },
      {
        lga: "OWO",
        chairmen: "ADEGBEBI SAMUEL",
      },
    ],
  },
  {
    field1: "29",
    state: "OSUN(30)",
    data: [
      { lga: "AIYEDAADE", chairmen: "HON. ADEYEYE ABDULLATEFF" },
      {
        lga: "AIYEDIRE",
        chairmen: "HON ADEBOYE MUKAILA OLADEJO",
      },
      {
        lga: "ATAKUMOSA EAST",
        chairmen: "HON OLUWANIFEMI SAMSON KUJEMBOLA",
      },
      {
        lga: "ATAKUMOSA WEST",
        chairmen: "HOM FRANCIS FAMUREWA",
      },
      {
        lga: "BOLUNWADURO",
        chairmen: "HON(PRINCE) HAKEEM A. TOKEDE",
      },
      {
        lga: "BORIPE",
        chairmen: "HON. ADEBAYO OYEYIOLA",
      },
      {
        lga: "EDE NORTH",
        chairmen: "HON DAUD AKINLOYE",
      },
      {
        lga: "EDE SOUTH",
        chairmen: "HON JOHNSON OJO",
      },
      {
        lga: "EGBEDORE",
        chairmen: "HON OLAYIWOLA ABIOLA",
      },
      {
        lga: "EJIGBO",
        chairmen: "HON. AYEGBAYO GBENGA O.",
      },
      {
        lga: "IFE CENTRAL",
        chairmen: "HON ELUGBAJU OLAYERA J.",
      },
      {
        lga: "IFE EAST",
        chairmen: "HON OLUSEGUN B. ADEYEMI",
      },
      {
        lga: "IFE NORTH",
        chairmen: "HON. TAIWO HASSAN OROSANYA",
      },
      {
        lga: "IFE SOUTH",
        chairmen: "HON ADEGBITE MUFUTAU A.",
      },
      {
        lga: "IFELODUN",
        chairmen: "HON LAMIDI OMOLOYE SHITTU",
      },
      {
        lga: "IFEDAYO",
        chairmen: "HON. ABIODUN SAMUEL IDOWU",
      },
      {
        lga: "ILA",
        chairmen: "HON.BARR ADEDIJI JOSHUA ADEMOLA",
      },
      {
        lga: "ILESHA EAST",
        chairmen: "HON. AKEEM ADESOJI O.",
      },
      {
        lga: "ILESHA WEST",
        chairmen: "HON. GIWA NURUDEEN",
      },
      {
        lga: "IREPODUN",
        chairmen: "HON. ANTHONY ABAYOMI OYEDEJI",
      },
      {
        lga: "IREWOLE",
        chairmen: "HON. PRINCE ADEREMI ABBAS",
      },
      {
        lga: "ISOKAN",
        chairmen: "HON.AKEEM OLADENI BADIRU",
      },
      {
        lga: "IWO",
        chairmen: "HON. RAJI KAMORU ABIODUN",
      },
      {
        lga: "OBOKUN",
        chairmen: "HON. ADEJOKE JOSEPHINE O.",
      },
      {
        lga: "ODO-OTIN",
        chairmen: "HON. SAMSON AYODELE ODUOYE",
      },
      {
        lga: "OLA-OLUWA",
        chairmen: "ENGR. OYEDIRAN ASIMIYU AMOBI",
      },
      {
        lga: "OLORUNDA",
        chairmen: "HON. HAMMED MUNIRUDEEN OLUWAFEMI",
      },
      {
        lga: "ORIADE",
        chairmen: "HON. OBEISUN EZEKIEL OLUBUNMI",
      },
      {
        lga: "OROLU",
        chairmen: "HON. BENSON ADEKUNLE",
      },
      {
        lga: "OSOGBO",
        chairmen: "HON. ADEGOKE MUSA O.",
      },
    ],
  },
  {
    field1: "30",
    state: "OYO(33)",
    data: [
      { lga: "AFIJIO", chairmen: "ADENIYI SAMUEL ADEREMI" },
      {
        lga: "AKINYELE",
        chairmen: "HON. TAOREED JIMOH ADEDIGBA",
      },
      {
        lga: "ATIBA",
        chairmen: "AKEEM ADENIYI ADEYEMI",
      },
      {
        lga: "ATIGBO",
        chairmen: "WAIUS OWOLABI",
      },
      {
        lga: "EGBEDA",
        chairmen: "HON. AKEEM SADIQ",
      },
      {
        lga: "IBADAN NORTH",
        chairmen: "HON. YINKA AKINBODE",
      },
      {
        lga: "IBADAN NORTH EAST",
        chairmen: "HON. OLALEKAN AFUYE",
      },
      {
        lga: "IBADAN NORTHWEST",
        chairmen: "OLATUNBOSUN W ADEWOLE",
      },
      {
        lga: "IBADAN SOUTH EAST",
        chairmen: "HON, ALAWODE EMMANUEL",
      },
      {
        lga: "IBADAN SOUTH WEST",
        chairmen: "NAJIMUDEEN ABASS BOLAJI",
      },
      {
        lga: "IBARAPA CENTRAL",
        chairmen: "HON. SHAFARADEEN OLAONIYE",
      },
      {
        lga: "IBARAPA EAST",
        chairmen: "OLADIJI O. OLAOSEGBA",
      },
      {
        lga: "IBARAPA NORTH",
        chairmen: "ALHAJI HON. TAJUDEEN SALAWU",
      },
      {
        lga: "IDO",
        chairmen: "JOSEPH ADENIYI OLOWOFELA",
      },
      {
        lga: "IREPO",
        chairmen: "HON.SULEIMANA LATEEF ADENIRAN",
      },
      {
        lga: "ISEYIN",
        chairmen: "ABOLADE YEKINNI AKANNI",
      },
      {
        lga: "ITESIWAJU",
        chairmen: "ADEAGBO ADENIYI A.",
      },
      {
        lga: "IWAJOWA",
        chairmen: "HON OYINLOYE JELILI ADEBARE",
      },
      {
        lga: "KAJOLA",
        chairmen: "SUNDAY F. OGUNGBENRO",
      },
      {
        lga: "LAGELU",
        chairmen: "ADEBAYO MUNEER ADEREMI",
      },
      {
        lga: "OGBOMOSO NORTH",
        chairmen: "OLADEJI A. OLANREWAJU",
      },
      {
        lga: "OGBOMOSO SOUTH",
        chairmen: "OLAJIDE ADISA ISAAC",
      },
      {
        lga: "OGO OLUWA",
        chairmen: "HON. OLUWASEGUN OJO",
      },
      {
        lga: "OLORUNSOGO",
        chairmen: "CHIEF AYANDEYI AYANTUNJI",
      },
      {
        lga: "OLUYOLE",
        chairmen: "POPOOLA OLAIDE SETTLE",
      },
      {
        lga: "ONA-ARA",
        chairmen: "FAWOLE OLUKAYODE BOLA",
      },
      {
        lga: "ORELOPE",
        chairmen: "ADENIBI SAMUEL NIYI",
      },
      {
        lga: "ORI-IRE",
        chairmen: "HON. YINKA ODEKANMI",
      },
      {
        lga: "OYO EAST",
        chairmen: "MUTAHIR A. ADEKUNLE",
      },
      {
        lga: "OYO WEST",
        chairmen: "OJOAWO ADESOJI RICHARD",
      },
      {
        lga: "SAKI EAST",
        chairmen: "ADDEBAYO WAHAB ADEWALE",
      },
      {
        lga: "SAKI WEST",
        chairmen: "NATHANIEL IDOWU AKANO",
      },
      {
        lga: "SURULERE",
        chairmen: "ADEGBITE ISAIAH",
      },
    ],
  },
  {
    field1: "31",
    state: "PLATEAU(17)",
    data: [
      { lga: "BARKIN LADI", chairmen: "HON JOK ALAMBA" },
      {
        lga: "BASSA",
        chairmen: "HON CHRIS HASSAN",
      },
      {
        lga: "BOKKOS",
        chairmen: "YUSUF MACHEN",
      },
      {
        lga: "JOS EAST",
        chairmen: "HON IBRAHIM AGWOM",
      },
      {
        lga: "JOS NORTH",
        chairmen: "HON BALA SHEHU USMAN",
      },
      {
        lga: "JOS SOUTH",
        chairmen: "MR GIDEON DUNG DAVOU",
      },
      {
        lga: "KANAM",
        chairmen: "HON ABBAS WAKDUNG",
      },
      {
        lga: "KANKE",
        chairmen: "HON NDE EMMANUEL LAR",
      },
      {
        lga: "LANTANG NORTH",
        chairmen: "HON UBANDOMA JOSHUA",
      },
      {
        lga: "LANTANG SOUTH",
        chairmen: "HON NIMCHAK RIMS",
      },
      {
        lga: "MANGU",
        chairmen: "TITUS BISE",
      },
      {
        lga: "MIKANG",
        chairmen: "HON EZEKIEL PABUET",
      },
      {
        lga: "PANKSHIN",
        chairmen: "NAOMI GOLMWEN",
      },
      {
        lga: "QUA`AN PAN",
        chairmen: "HON ISAAC KWALLU",
      },
      {
        lga: "RIYOM",
        chairmen: "MAFENG GWALSON",
      },
      {
        lga: "SHENDAM",
        chairmen: "HON. ALEXANDER MISKOOM NAANTUAM",
      },
      {
        lga: "WASE",
        chairmen: "HON NYAKO SIMAN",
      },
    ],
  },
  {
    field1: "32",
    state: "RIVERS(23)",
    data: [
      { lga: "ABUA/ODUAL", chairmen: "DANIEL E.O DANIEL" },
      {
        lga: "AHAODA EAST",
        chairmen: "BENJAMIN EKE",
      },
      {
        lga: "AHAODA WEST",
        chairmen: "IKIRIKO HOPE",
      },
      {
        lga: "AKUKUTORU",
        chairmen: "ROWLAND SEKIBO",
      },
      {
        lga: "ANDONI",
        chairmen: "AWORTU ERASTUS",
      },
      {
        lga: "ASARI-TORU",
        chairmen: "OMENGIYEOFORI GEORGE",
      },
      {
        lga: "BONNY",
        chairmen: "IRIMAGHA DAVID R",
      },
      {
        lga: "DEGEMA",
        chairmen: "MICHAEL J. WILLIAMS",
      },
      {
        lga: "EMOHUA",
        chairmen: "LLOYD CHIDI",
      },
      {
        lga: "ELEME",
        chairmen: "OBARILLOMATE OLLOR",
      },
      {
        lga: "ETCHE",
        chairmen: "OBINNA ANYANWU",
      },
      {
        lga: "GOKANA",
        chairmen: "DEKO CONFIDENCE",
      },
      {
        lga: "IKWERRE",
        chairmen: "NWANOSIKE SAMUEL",
      },
      {
        lga: "KHANA",
        chairmen: "BARIERE THOMAS",
      },
      {
        lga: "OBIA/AKPOR",
        chairmen: "ARIOLU C. GEORGE",
      },
      {
        lga: "OGBA/EGBEMA/NDONI",
        chairmen: "VINCENT JOB",
      },
      {
        lga: "OGU/BOLO",
        chairmen: "NEMIEBOKA VINCENT",
      },
      {
        lga: "OKRIKA",
        chairmen: "AKURO TOBIN",
      },
      {
        lga: "OMUMMA",
        chairmen: "NWAIWU CHISOROM",
      },
      {
        lga: "OPOBO/NKORO",
        chairmen: "ENYIADA COOLEY-GAM",
      },
      {
        lga: "OYIGBO",
        chairmen: "NWAOGU AKARA",
      },
      {
        lga: "PORT-HARCOURT",
        chairmen: "IHUNDA ALLWELL",
      },
      {
        lga: "TAI",
        chairmen: "MBAKPONE OKPE",
      },
    ],
  },
  {
    field1: "33",
    state: "SOKOTO(23)",
    data: [
      { lga: "BINJI", chairmen: "HON. UMMARU DANHABBI" },
      {
        lga: "BODINGA",
        chairmen: "HON. JANAIDU ABUBAKAR",
      },
      {
        lga: "DANGE-SHUNI",
        chairmen: "HON MODE DANTASALLAH AMANAWA",
      },
      {
        lga: "GADA",
        chairmen: "HON. DR ABDULLAHI AHMAD  GADA",
      },
      {
        lga: "GORONYO",
        chairmen: "HON. FAROUK YAR RIMAWA",
      },
      {
        lga: "GUDU",
        chairmen: "HON. KABIRU A. MAIZAMA",
      },
      {
        lga: "GWADABAWA",
        chairmen: "HON. ISAH TAMBAGARKA",
      },
      {
        lga: "ILLELA",
        chairmen: "ENGR ALIYU SALIHU",
      },
      {
        lga: "ISA",
        chairmen: "HON COL GARBA MOYI",
      },
      {
        lga: "KWARE",
        chairmen: "HON. USMAN MOHAMMED BALKORE",
      },
      {
        lga: "KEBBE",
        chairmen: "HON LAWALI MARAFA",
      },
      {
        lga: "RABAH",
        chairmen: "ALHAJI SALISU LAWALI GANDI",
      },
      {
        lga: "SABON BIRNI",
        chairmen: "HON IDRIS MOH`D GOBIR",
      },
      {
        lga: "SHAGARI",
        chairmen: "HON. MOH`D JABBI SHAGARI",
      },
      {
        lga: "SILAME",
        chairmen: "ALHAJI MANI MAI-SHINKI",
      },
      {
        lga: "SOKOTO NORTH",
        chairmen: "HON. MALAM UMAR AHMED",
      },
      {
        lga: "SOKOTO SOUTH",
        chairmen: "HON AMINU SAHALI",
      },
      {
        lga: "TANBUWAL",
        chairmen: "MUHAMMEDD  BELLO",
      },
      {
        lga: "TANGAZA",
        chairmen: "HON. NURA SHEHU",
      },
      {
        lga: "TURETA",
        chairmen: "HON ALIYU ABBAKAR",
      },
      {
        lga: "WAMAKKO",
        chairmen: "HON. BUBA BELLO",
      },
      {
        lga: "WURNO",
        chairmen: "HON ABDULKADIR JELANI SHEHU",
      },
      {
        lga: "YABO",
        chairmen: "BABANGIDA TAMBARI DAGAWA",
      },
    ],
  },
  {
    field1: "34",
    state: "TABARA(16)",
    data: [
      { lga: "ARDO-KOLA", chairmen: "SALIHU DOVO" },
      {
        lga: "BALI",
        chairmen: "MUSA MAHMAUD ADAMU",
      },
      {
        lga: "DONGA",
        chairmen: "HON IPEYEN MUSA",
      },
      {
        lga: "GASHAKA",
        chairmen: "MOHAMMED GAYAM",
      },
      {
        lga: "GASSOL",
        chairmen: "YAHUZA YAYAU",
      },
      {
        lga: "IBI",
        chairmen: "BALA BAKO SUNGBADO",
      },
      {
        lga: "JALINGO",
        chairmen: "ABDULNASIRU BOBBOJI",
      },
      {
        lga: "KARIM-LAMIDO",
        chairmen: "IDI MALLI DANFULANI",
      },
      {
        lga: "KURMI",
        chairmen: "HON STEPHEN IBRAHIM AGYA",
      },
      {
        lga: "LAU",
        chairmen: "NICHOLAS WANIYAFI WANI",
      },
      {
        lga: "SARDAUNA",
        chairmen: "JOHN YEP",
      },
      {
        lga: "TAKUM",
        chairmen: "SHIBAN Y. TIKARI",
      },
      {
        lga: "USSA",
        chairmen: "RIMAMSIKWE HASSAN KARMA",
      },
      {
        lga: "WUKARI",
        chairmen: "ADI DANIEL",
      },
      {
        lga: "YORRO",
        chairmen: "JOSEPH SANWERE MIKA",
      },
      {
        lga: "ZING",
        chairmen: "CHRISTOPHER KASHONBA",
      },
    ],
  },
  {
    field1: "35",
    state: "YOBE(17)",
    data: [
      { lga: "BADE", chairmen: "ALHAJI SANDA KARABADE" },
      {
        lga: "BURSARI",
        chairmen: "ALHAJI ZANNA ABATCHA",
      },
      {
        lga: "DAMATURU",
        chairmen: "HON. BUKAR ADAMU",
      },
      {
        lga: "FIKA",
        chairmen: "HAJIYA HALIMA KYARI JODA",
      },
      {
        lga: "FUNE",
        chairmen: "UMAR SANDA",
      },
      {
        lga: "GEIDAM",
        chairmen: "HON. ALH ALI KOLO KACHALLAH",
      },
      {
        lga: "GUJBA",
        chairmen: "DALA MUSA",
      },
      {
        lga: "GULANI",
        chairmen: "BABAYI HASSAN",
      },
      {
        lga: "JAKUSKO",
        chairmen: "IBRAHIM MOHAMMED ISA",
      },
      {
        lga: "KARASUWA",
        chairmen: "HON, SIDI YAKUBU",
      },
      {
        lga: "MACHINA",
        chairmen: "IDRIS BUKAR",
      },
      {
        lga: "NANGERE",
        chairmen: "HARUNA ALIYU BAGARE",
      },
      {
        lga: "NGURU",
        chairmen: "ALI MAIDAMI",
      },
      {
        lga: "POTISKUM",
        chairmen: "ALHAJI ADO HAMZA NGOJIN",
      },
      {
        lga: "TARMUWA",
        chairmen: "HON MUHAMMED LAMIDO MUSA",
      },
      {
        lga: "YUNUSARI",
        chairmen: "SALEH MUSA GULANI",
      },
      {
        lga: "YUSUFARI",
        chairmen: "BALAMA BUDU",
      },
    ],
  },
  {
    field1: "36",
    state: "ZAMFARA(14)",
    data: [
      { lga: "ANKA", chairmen: "ALHAJI AHMED BALARABE" },
      {
        lga: "BAKURA",
        chairmen: "DANDARE DANDAKO",
      },
      {
        lga: "BIRNI MAGAJI",
        chairmen: "ALHAJI MUHAMMAD UMAR",
      },
      {
        lga: "BUKKUYUM",
        chairmen: "ALHAJI NASIRU ZARUMI",
      },
      {
        lga: "BUNGUDU",
        chairmen: "ALHAJI ABDULAZIZ NAHUCHE",
      },
      {
        lga: "GUMMI",
        chairmen: "KABIRU S. ASKI",
      },
      {
        lga: "GUSAU",
        chairmen: "ALHAJI BABAANGIDA",
      },
      {
        lga: "KAURA NAMODA",
        chairmen: "ALHAJI LAWAL ABDULLAHI",
      },
      {
        lga: "MARADUN",
        chairmen: "ALHAJI SHEHU FARU",
      },
      {
        lga: "MARU",
        chairmen: "ALHAJI SALISU DANGULBI",
      },
      {
        lga: "SHINKAFI",
        chairmen: "SANI GALADI",
      },
      {
        lga: "TARATA MAFARA",
        chairmen: "LAWAL MARAFA",
      },
      {
        lga: "TSAFE",
        chairmen: "ALHAJI AMINU MUDI",
      },
      {
        lga: "ZURMI",
        chairmen: "AWWAL BAWA",
      },
    ],
  },
  {
    field1: "37",
    state: "ABUJA F.C.T(6)",
    data: [
      { lga: "ABAJI", chairmen: "HON ABDULRAHAM AJIYA" },
      {
        lga: "ABUJA MUNICIPAL",
        chairmen: "HON ABDULLAHI ADAMU",
      },
      {
        lga: "BWARI",
        chairmen: "MR JOHN GABAYA",
      },
      {
        lga: "GWAGWALADA",
        chairmen: "ADAMU MUSTAPHA DANZE",
      },
      {
        lga: "KUJE",
        chairmen: "ALHAJI ABDULLAHI SULEIMAN SABO",
      },
      {
        lga: "KWALI",
        chairmen: "MR DANLADI CHIYA",
      },
    ],
  },
];

export default lgaData;
